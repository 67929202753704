@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'archia';
  src: url('./assets/fonts/archia-light-webfont.eot');
  src: url('./assets/fonts/archia-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/archia-light-webfont.woff2') format('woff2'),
  url('./assets/fonts/archia-light-webfont.woff') format('woff'),
  url('./assets/fonts/archia-light-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'archia';
  src: url('./assets/fonts/archia-regular-webfont.eot');
  src: url('./assets/fonts/archia-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/archia-regular-webfont.woff2') format('woff2'),
  url('./assets/fonts/archia-regular-webfont.woff') format('woff'),
  url('./assets/fonts/archia-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'archia';
  src: url('./assets/fonts/archia-medium-webfont.eot');
  src: url('./assets/fonts/archia-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/archia-medium-webfont.woff2') format('woff2'),
  url('./assets/fonts/archia-medium-webfont.woff') format('woff'),
  url('./assets/fonts/archia-medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'archia';
  src: url('./assets/fonts/archia-semibold-webfont.eot');
  src: url('./assets/fonts/archia-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/archia-semibold-webfont.woff2') format('woff2'),
  url('./assets/fonts/archia-semibold-webfont.woff') format('woff'),
  url('./assets/fonts/archia-semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

html {
  font-size: 14px;

  --primary: #c91444;
  --custom-primary: #c91444;
  --secondary: #640d24;
  --category-01: #8f72eb;
  --base-silver: #bec7ca;
  --base-bright-silver: #e1e4e5;
  --base-deep-black: #0e0e0e;
  --base-anthracite: #201c1e;
  --signal-yellow: #e4bc40;
  --signal-error: #cb513b;
  --collapse-opened-text-color: #fff;

  --primary-gradient: linear-gradient(to right, #aa0c54, #cd1543 40%);
  --secondary-gradient: linear-gradient(to right, #461fc2, #5f45b5 40%);

  --h1-size: 24px;
  --h1-weight: 500;

  --h2-size: 20px;
  --h2-weight: 500;
  --h2-pt: 56px;
  --h2-pb: 22px;

  --h3-size: 16px;
  --h3-weight: 500;
  --h3-pt: 26px;
  --h3-pb: 14px;

  --nav-height: 60px;
  --nav-padding-y: 10px;
  --nav-padding-x: 16px;

  --content-header-height: var(--nav-height);
  --content-padding-x: var(--nav-padding-x);
  --content-padding-y: var(--nav-padding-y);
  --subcontent-padding-y: 26px;

  --btn-height: 48px;
  --btn-padding: 14px;
  --btn-border-radius: 8px;

  --form-field-short-flex-basis: 56px;

  --map-menu-width: 400px;
  --mobile-map-menu-width: 390px;
}

@media (min-width: 640px) {
  html {
    --nav-padding-x: 35px;
  }
}

* {
  -webkit-font-smoothing: antialiased;
}

.sub-content {
  padding-top: var(--subcontent-padding-y);
}

.btn {
  height: var(--btn-height);
  padding: var(--btn-padding);
  line-height: 1;
  text-transform: none;
  border-radius: var(--btn-border-radius);
  @apply text-base font-normal;
}

.bg-primary-gradient {
  color: white;
  background: var(--primary-gradient);
}

.bg-primary-gradient.loading {
  color: white;
}

.bg-primary-gradient:disabled {
  background: white;
  color: var(--base-silver);
  border: 1px solid var(--base-silver);
}

.bg-primary-gradient:disabled img {
  filter: brightness(0.7);
}

.bg-secondary-gradient {
  color: white;
  background: var(--secondary-gradient);
}

.bg-secondary-gradient.loading {
  color: white;
}

.bg-secondary-gradient:disabled {
  background: white;
  color: var(--base-silver);
  border: 1px solid var(--base-silver);
}

.bg-secondary-gradient:disabled img {
  filter: brightness(0.7);
}

.bg-white-primary {
  color: var(--primary);
  background: white;
}

.form-control.min-flex {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: var(--form-field-short-flex-basis);
}

.form-control label {
  @apply relative;
}

.form-control label span {
  color: var(--base-silver);
  left: 12px;
  top: 7px;
  z-index: 90;
  padding: 0 8px;
  @apply text-sm absolute bg-white;
}

.form-control:focus-within label span {
  color: var(--primary);
  @apply font-normal;
}

.form-control span.error {
  color: var(--primary);
}

.form-control .input, .form-control select {
  min-height: 48px;
  color: var(--base-anthracite);
  @apply font-normal;
}

.form-control .input-bordered:focus, .form-control .input-bordered:hover, .form-control .select-bordered:focus, .form-control .select-bordered:hover {
  outline: none !important;
  border: 1px solid var(--primary);
  box-shadow: none !important;
}

.form-control .input-bordered {
  border: 1px solid var(--base-bright-silver);
}


/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select, textarea, input[type="text"], input[type="number"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select, textarea, input[type="text"], input[type="number"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select, textarea, input[type="text"], input[type="number"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="tel"], input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select, textarea, input[type="text"], input[type="number"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="tel"], input[type="url"] {
    font-size: 16px;
  }
}
