.nav {
  height: var(--nav-height);
  @apply relative flex items-center;

  .nav-logo {
    padding: var(--nav-padding-y) var(--nav-padding-x);
    @apply h-full flex items-center;
  }

  .nav-links {
    @apply flex h-full w-full;
    .nav-link {
      padding: 0 var(--nav-padding-x);
      line-height: normal;
      justify-content: space-around;
      color: var(--base-anthracite);
      @apply h-full font-medium flex items-center text-center;
      &.active {
        color: var(--primary);
        border-bottom: 4px solid var(--primary);
      }
    }
  }

  .nav-usermenu {
    @apply h-full relative;
    .nav-usermenu-button {
      @apply h-full w-20 flex justify-center items-center;

      button {
        padding: 3px;
        border: 1px solid var(--base-bright-silver);
        height: 48px;
        width: 48px;
        @apply bg-white rounded-full focus:outline-none;
      }

      img {
        @apply h-full rounded-full;
      }
    }

    .nav-usermenu-link {
      @apply block px-4 py-2;
    }
  }

  .nav-button {
    left: 20px;
    @apply absolute inset-y-0 flex items-center lg:hidden;
  }

  .nav-mobile-menu .nav-mobile-menu-link {
    @apply block px-3 py-2 rounded-md text-base font-medium;
    &.active {
      color: var(--primary);
    }
  }
}

@media (min-width: 768px) {
  .nav .nav-logo, .nav .nav-links .nav-link {
    border-right: 1px solid var(--base-bright-silver);
  }
}
